.contentEditable {
    padding: 15px;
    font-size: 17px;
    min-height: 150px;
}

.placeholder {
    position: absolute;
    font-size: 1.2em;
    top: 0;
    margin: 15px;
}

.editorWrapper {
    position: relative;
    background-color: white;
    width: 80%;
    min-height: 40%;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
}

.previewImage {
  width: '100%';
  height: '100%';
  object-fit: 'cover';
  opacity: 0.7;
  position: 'absolute';
  top: 0;
  left: 0;
  z-Index: 1;
}

.previewImageContainer { 
  position: 'relative';
  width: '100%';
  height: '100%' 
}

.previewText {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  height: '100%';
  color: 'white';
  font-size: '20px';
  position: 'absolute';
  top: 0;
  left: 0;
  width: '100%';
  z-index: 1;
}

/*! Mobile Preview */
.mobile-preview-container {
  width: 375px;
  height: 667px;
  border: 16px solid black;
  border-radius: 36px;
  background-color: white;
  overflow: hidden;
  margin: 0 auto;
}

.mobile-preview-header {
  height: 30px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.mobile-preview-notch {
  width: 60px;
  height: 10px;
  background: black;
  border-radius: 10px;
}

.mobile-preview-content {
  padding: 16px;
  height: calc(100% - 30px);
  overflow-y: auto;
}
