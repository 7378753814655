.gallery-section{
    background-image: url(../../assets/images/ahc-gallery-banner.jpeg);
    background-size: cover;
    margin-top: 65px;
    background-repeat: no-repeat;
    width:100%;
    height: 300px;
    position: relative;
}

.gallery-header{
    color: #50A895;
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    margin: 20px;
}




@media only screen and (max-width: 768px){
    .header-case {
        font-size: 3rem;
        text-align: center;
        margin-top: 80px;
        margin-right: 50px;
        position: sticky;
        color: #fff;
        top: 20%;
        z-index: 200;
        left: 41%;
        font-weight: bold;
        position: absolute;
        left: 30%;
    }

}

    





