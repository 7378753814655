.case-studies-section{
    background-image: url(../../assets/images/Case-Studies.png);
    background-size: cover;
    margin-top: 65px;
    background-repeat: no-repeat;
    width:100%;
    height: 300px;
    position: relative;
    display: flex; 
}

.case-study-heading{
    font-size: 3rem;
    text-align: center;
    margin-top: 80px;
    margin-right: 50px;
    position: sticky;
    color: #fff;
    top: 20%;
    z-index: 200;
    font-weight: bold;
    left: 40%;
}  

.case-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    /* top: 40px; */
    background-color: rgba(0,0,0,.5);
    /* z-index: 1; */
}

.search-container{
    display: flex;
    width: 75%;
    border-radius:5px;
    justify-content:space-around;
    align-items:center;
    background-color: #fff !important;
    position: absolute;
    height:50px;
    top: 50%;
    left: 13%;
    height: 50px;
    padding: 10px;
}

.header-case{
    font-size: 3rem;
    text-align: center;
    position: absolute;
    color: #fff;
    top: 20%;
    z-index: 200;
    left: 41%;
    font-weight: bold;
}

.search-case{
    width: 95%;
    /* border: black solid 1px; */
    /* background-color: #fff; */
}

.cases-contain{
    display:flex;
    padding: 75px;
}

.search-case-icon{
    font-size: 1.5em;
}

.cases-column{
    display:flex;
    flex-direction:column;
    width:200px;
    margin-top: 20px;
    margin-left: -70px ;
}

.case-company-card{
     width: 30%;
    border-radius: 8px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    /* margin-right: 150px; */    
  
}
.case-select{
    border: 1px solid #000;
    padding:10px;
    margin-bottom:15px;
}

.case-logo{
    margin: auto;
}

.cases-grid {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin-left:50px;
    justify-content: space-around;
    align-items: space-around;
}

.case-card-heading{
    margin: 10px;
    margin-left: 35px;
    font-weight: 600;
}
.case-card-subheading{
    margin: 10px;
    margin-left: 35px;
    color: #9B9494;
}

.case-card-text{
    padding: 20px 35px;
    border-top: 1px solid #d7d7d7;
}

@media only screen and (max-width: 768px) {
    /* tablets and desktop */   
    .case-company-card {
        /* min-height: 500px; */
        width: 355px !important;
        border-radius: 8px;
        margin-top: 20px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 50px;
    }

    .cases-column{
        display:flex;
        flex-direction:column;
        width:200px;
        margin-top: 20px;
        margin-left: -45px ;
    }
    
   
    .cases-contain {
        display: flex;
        flex-direction: column;
        padding: 75px;
    }
    .cases-column {
        display: flex;
        flex-direction: column;
        width: 200px;
        margin-top: 20px;
        margin-left: 50px;
        /* margin-left: -45px; */
    }
}

@media only screen and (max-width: 767px) {
    /* phones */
}


@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}

@media only screen and (max-width: 1025px) {
    /* laptops */
}