.green-section{
    background-color:#006750;
    display: flex;
    justify-content: center;
    align-items: center;
    height:400px;
    margin-top: 65px;
}

  .videoWrapper {
        position: relative;
         padding-bottom: 56.25%; /* 16:9 */
         padding-top: 25px;
         height: 0;
         margin: 0 auto; /* Add this line */
         width: 80%;
    }
      
    .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

.summit-venue{
    background-color:#F4F4F4;
    display:flex;
    padding:15px;
}

.summit-logo{
    width:320px;
    height:200px
}

.summit-list{
    margin-left: 60px;
    margin-top: 18px;
    color: #656565;
}
.agenda-list{
    margin-left: 30px;
    margin-top: 18px;
    color: #656565;
}

.venue-image{
    width:55%;
}

.venue-text{
    width:70%;
    font-size: 0.9rem;
    text-align:left;
}

.partner-logo-scrollable{
    width: 10%;
    margin-left: 20px;
}

.agenda-container{
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin: auto;
    margin-top: 65px;
    margin-bottom: 40px;

}

.agenda-column{
    display: flex;
    flex-direction:column;
    width: 47%;
}

.agenda-dark-heading{
    background-color: #006750;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.agenda-light-heading{
    background-color: #50A895;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.agenda-dark-text{
    color: #006750;
    text-align: center;
}
.agenda-light-text{
    color: #50A895;
    text-align: center;
}

.venue-column{
    display: flex;
    padding: 30px;
    padding-left: 80px;
    flex-direction: column;
}

.venue-image-contain{
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
    /* tablets and desktop */

    .venue-column {
        display: flex;
        padding: 30px;
        padding-left: 80px;
        flex-direction: column;
        width: 400px;
        height: 350px;
        overflow-clip-margin: content-box;
    }
    
    .venue-text {
        overflow: hidden;
        width: 70%;
        font-size: 0.8rem;
        text-align: left;
        text-decoration-line: blink;
        overflow-clip-margin: inherit;
        margin-left: -70px;
    }
    .venue-image {
        width: 300px;
        width: 300px;
        margin-left: -100px;
        padding-right: 100px;
        position: absolute;
        height: 130px;
        margin-top: 100px;
    }

    .agenda-dark-heading{
        padding: 20px;
    }
    .agenda-light-heading{
        padding: 20px;
    }

    .hori-scroll {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        width: 95%;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .partner-logo-scrollable {
        width: 20%;
        margin-left: 50px;
    }

     
    

}