@import "rsuite/dist/rsuite.css";

/* #root{ padding: 10px; } */

.imgDash{
    width: 20%;
    height: 70%;
}
.navitemside{
    background-color: transparent !important; 
}

.linkitem {
    text-decoration: transparent !important;
    color: inherit; 
  }
  
  .linkitem:hover {
    text-decoration: none; 
    color: inherit;
  }
  