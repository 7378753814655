.home-navbar {
    width: 100%;
    position: absolute;
    top: 20;
    z-index: 10;
}

.main {
    width: 100%;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shadow-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.init-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    top: 65px;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
}

.grid-container{
    width: 88%;
    margin: auto;
    margin-top:20px;

}

.page-text{
    color: #656565;
    font-size:1rem;
}
.green-text{
    color: #006750;
    font-size:1rem;
}

.green-header{
    font-size:1.5rem;
    color:#006750;
}

.row{
    display: flex;
    justify-content:center;
    align-items:center;
}

.half-width{
    width:50%;
}

.list-item{
    list-style: disc;
    margin-left: 30px;
}

.dark-green-bg{
   background-color: #3A817A;
   /* background-color: #4E807A; */
}

.line-height-35{
    line-height: 35px;
}

.btn{
    background-color: #fff;
    padding:20px;
}

.section-white{
    background-color: #fff;
    padding:70px;
    padding-left: 250px;
}
.section-gray{
    background-color:#F4F4F4;
    padding:70px;
    padding-left: 250px;

}

.section-header{
    font-size: 2.5rem;
    font-weight: bold;
}

.paragraph-padding{
    padding: 50px;
    background-color: #fff;
    display: flex;
    flex-direction:column;
    /* align-items: center; */
    /* justify-content:center; */
}

.smaller-text{
    font-size: 1em !important;
}

.paragraph-width{
    width: 85%;
}

.bottom-margin{
    margin-bottom: 15px;
}

.disc-list{
    list-style: disc;
}

.center-flex{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.hori-scroll{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 95%;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.center{
    display: flex;
    align-items: center;
    justify-content:center;
}

.bold{
    font-weight: bold;
}
.hori-scroll-spacing{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}


.hover-black:hover{
    background-color: #000;
    color: #fff;
}
.hover-green:hover{
    background-color: #50A895;
    color: #fff;
}


@media only screen and (max-width: 768px) {
    /* tablets and desktop */
    .row{
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    .section-header{
        font-size: 1.5em;
        font-weight: bold;
        padding-left: 140px;
    }
   
    .btn-join {
        color: #3A817A;
        font-size: 1.7rem;
        line-height: 1;
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 15px;
        padding-bottom: 15px;

    }
    .btn {
        background-color: #fff;
        width: 150px;
        padding: 20px;
        left: unset;
        margin: auto;
    }
    .roadmap-container {
        width: 100%;
        margin-top: 40px;
    }

    .partner-logo {
        width: 40%;
        margin: 30px;
    }

}

