@import "rsuite/dist/rsuite.css";

/* #root{ padding: 10px; } */

.contentContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.datacontainer{
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} 
.textcontainer{
    background-color: #F5FFF5;
    padding: 3px;
    border-radius: 5px;
}

.rs-table-scrollbar {
    background-color: white !important;
  }
  .btnadd{
    background-color:#2D9687;
    display:flex;
    flex-direction:row;
    justify-items:center;
    align-items:center;
    padding-left:5px;
    padding-right:5px;
    height: 85%;
    color: white;
    border-radius: 5px;
  }

  .custom-select .ant-select-selector {
    border-radius: 5px !important; 
  }

  .ant-select-selector:hover {
    border-color: #2D9687 !important; 
  }

  .ant-select-selector:focus {
    border-color: #2D9687 !important; 
  }

  .tblcontainer {
    width: 95%;
    background-color: white;
    padding: 1em;
    border-radius: 1em;
    border-width: 1px;
    padding-bottom: 2em;
  }

  .hdcontainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 13%;
    align-items: center;
  }

  .mdbtnadd {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2D9687;
    color: white;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .mdinput:hover{
    border-color:#2D9687 ;
  }

  #btnmd{
    background-color: #2D9687;
  }

  #btnmd:hover{
    border-color: #2D9687;
    color: #F5FFF5;
  }

.inpsearch,
.inpsearch:focus {
  border-width: 0;
  outline: none;
  width: 100%;
}
  



  