        .header-privacy{
        font-size: 3rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 20%;
        z-index: 200;
        left: 30%;
        font-weight: bold;
    }
  .page-text-privacy {
        color: #656565;
        font-size: 1rem;
        overflow-clip-margin: inherit;
        overflow: inherit;
    }

 


@media only screen and (max-width: 768px){

   
    .header-privacy {
        font-size: 2rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 40%;
        z-index: 200;
        left: 7%;
        font-weight: bold;
    }

}