.header-nominate{
    font-size: 3rem;
    text-align: center;
    position: absolute;
    color: #fff;
    top: 20%;
    z-index: 200;
    left: 30%;
    font-weight: bold;
}

.submit-champion{
    padding:25px;
    background-color: #50A895;
    color:#fff;
    width: 200px;
    margin-top:10px;
}


@media only screen and (max-width: 768px){

   
    .header-nominate {
        font-size: 2rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 20%;
        z-index: 200;
        left: 7%;
        font-weight: bold;
    }

}

