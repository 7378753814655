
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}


.initiative-section{
    background-image: url(../../assets/images/About-the-initiative.png);
    background-size: cover;
    margin-top: 65px;
    background-repeat: no-repeat;
    width:100%;
    height: 300px;
}



.header-about-init{
    font-size: 3rem;
    text-align: center;
    position: absolute;
    color: #fff;
    top: 20%;
    z-index: 200;
    left: 35%;
}

.section-why{
    background-color: #3A817A;
    padding:40px;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.why-card {
    width: 335px;
    height: 468px;
    background-color:#fff;
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items:center;
    padding:30px;
    margin-left: 20px;
}

.why-text{
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
}

.why-number{
    border: 8px solid #3A817A;
    border-radius: 9px;
    text-align: center;
    color: #3A817A;
    font-size:3rem;
    width: 80px;
    height: 80px;
    display: flex;
    font-weight: bold;
    justify-content:center;
    align-items: center;
    margin: 45px;

}

.why-paragraph{
    width: 200px;
    text-align: center;
}

.section-continent{
    display: flex;
    
}

.section-continent-left{
    display: flex;
    flex-direction:column;
    width:35%;
    background-color: #F4f4f4;
    height:510px;
    padding-left:120px;
    padding-right:35px;
}
.section-continent-right{
    display: flex;
    flex-direction:column;
    padding-left:50px;
}

.world-icon{
    color: #3A817A;
    font-size: 5rem;
    margin-top: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.h2-africa{
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 15px;
}

.africa-map{
    width: 320px;
    height: 350px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.section-partners{
    display: flex;
    background-color: #E3E3E3;
    align-items:center;
    height:100vh;
}

.our-partners{
    color:#3A817A;
    font-size: 3rem;
    font-weight: bold;

}

.partners-container{
    width:30%;
}

.our-partners-container{
    padding-left: 8%;
}
.partners-text{
    margin-bottom:15px;
    margin:10px;
    text-align: center;

}

.partner-card {
    background-color:#fff;
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:30px;
    padding-right:90px;
    padding-left:90px;
    margin-left: 20px;
    text-align: center;
}

.about-partner-header{
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.partner-logo{
    width:60%;
    margin: 30px;
}

.black{
    color:black;
}

.white{
    color:white;
}

.section-roadmap{
    padding-top:80px;
    display:flex;flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
}

.roadmap-text{
    font-weight: bold;
    font-size: 2.8rem;
}



.section-roadmap{
    display: flex;
    /* background-color: #E3E3E3; */
    align-items:center;
    height:100vh;
}

.roadmap-card {
    background-color:#fff;
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    padding:30px;
    padding-right:40px;
    padding-left:40px;
    margin-left: 20px;
    text-align: center;
    height:400px;
}

.roadmap-container{
    width:22%;
    margin-top: 40px;
}

.roadmap-icon{
    color: #50A895;
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.roadmap-card-text{
    margin-bottom:15px;
    margin:10px;
    text-align: center;
    font-size:1.2rem;
    font-weight:normal;

}

.section-participate{
    background-color:#4E807A;
    display:flex;
    justify-content:space-around;
    padding: 70px;;
}

.participate-text{
    font-weight: bold;
    font-size: 2.5rem;
}

.btn-join{
    color: #3A817A;
    font-size: 1.2rem;
    line-height: 1;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
}
.yt-vid{
    padding: 70px;
}

@media only screen and (max-width: 768px) {
    /* tablets and desktop */

    .participate-text {
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 10px;
    }

    .our-partners{
        text-align: center;
        color: #3A817A;
        font-size: 1.5rem;
        margin-left: 100px;
        width: 150px;
        font-weight: bold;
    }

    .why-card {
       margin-bottom:15px;
    }

    .header-about-init{
        margin-right: 400px;
        position: absolute;
        /* left: 37%;
        top: 10%; */
    }
    .init-overlay{
        margin-top: 50px;
    }

    .section-continent-left {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #F4f4f4;
        padding-left: 10px;
        padding-bottom: 30px;
        
    }
    .section-continent {
        display: flex;
        flex-direction: column;
    }
    .page-text {
        color: #656565;
        font-size: 1rem;
        text-align: center;
        overflow-clip-margin: inherit;
        overflow: inherit;
    }
    .h2-africa {
        font-weight: bold;
        font-size: 0.9rem;
        margin-top: 10px;
    }
    .africa-map {
        width: 300px;
        /* margin-left: -110;
        margin-left: -450px;
        position: absolute;
        padding-right: 90px;
        margin-top: 480px;
        margin-bottom: 60px;
        padding:50px; */
    }

    .section-partners {
        position: relative;
        margin-top: 30px;
        background-color: #E3E3E3;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* height: 100vh; */
        /* margin-left: -250px; */
    }



   
    /* .section-partners {
        display: flex;
        background-color: #E3E3E3;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        margin-left: -250px;
    } */

    
   
    .partner-card {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        /* padding-right: 0px; */
        /* padding-left: 100px; */
        margin-left: 200px;
        text-align: center;
        width: 100%;
        margin-right: 200px;
    }

    .about-partner-header {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: -20px;
        padding: inherit;
    }



    .roadmap-container {
        width: 100%;
        margin-top: 40px;
    }
    
    /* .section-participate {
        background-color: #4E807A;
        display: flex;
        justify-content: space-around;
        padding: 70px;
    } */

    .section-participate {
        background-color: #4E807A;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-around;
        padding: 20px;
    }



    .partner-logo {
        width: 40%;
        margin: 30px;
    }

    .our-partners-container {
        /* margin-right: 300px; */
        padding-left: 0px;
        width: 350px;
        text-align: center;
    }

    .section-roadmap {
        margin-top: 850px;
        display: flex;
        height: initial;
        position: relative;
        flex-direction: column;
        /* position: relative; */
        flex-direction: column;
        /* background-color: #E3E3E3; */
        align-items: center;
        /* z-index: 5; */
        /* height: 100vh; */
    }

    .roadmap-text {
        font-weight: bold;
        font-size: 2.5rem;
    }





  
  
}