.people-section{
    background-image: url(../../assets/images/People-bg.png);
    background-size: cover;
    margin-top: 65px;
    background-repeat: no-repeat;
    width:100%;
    height: 300px;
}



.header-people{
    font-size: 3rem;
    text-align: center;
    position: absolute;
    color: #fff;
    top: 20%;
    z-index: 200;
    left: 45%;
    font-weight: bold;
}


.card-header {
  position: relative;
}

.card-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.card-header h3 {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
    /* phones */
    .header-people{
        position: sticky;
    }

    .header-people {
        font-size: 3rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 20%;
        z-index: 200;
        left: 35%;
        font-weight: bold;
    }
}

