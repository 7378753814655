
.image-container {
  display: flex;
}

.image-container img {
  height: auto; /* This will maintain the image's aspect ratio */
}



.home-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,.5);
    z-index: 1;
}

.home-text-inner{
    width: 55%;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    line-height: 24px;
}

.home-summit-image{
    width: 100%;
}

.home-text{
   
    color:#fff;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: absolute;
}

.home-btn-learn{
    background-color:#50A895;
    width: 150px;
    height: 50px;
    color:#fff;
    font-size:18px;
    padding:5px;
    margin:10px;
    margin-bottom: 80px;
}

.home-btn-enter{
    background-color:#fff;
    width: 190px;
    height: 60px;
    color:#000;
    font-size:18px;
    padding:5px;
    margin-top:20px;
}

/* .main{
    display: flex;
    flex-direction:column;
} */

.home-content-section{
    /* height: 100vh; */
    width:100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;;

}

.home-summit-image{
    height: 90px;
    width: 230px;
}
.home-afg-deg{ 
    height: 38px;
}
.mg-top-15{
    margin-top: 15px;
}

.news-container {
  position: relative;
}

.news-link {
  display: inline-block;
  position: relative;
}

.news-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
}

.news-icon {
  color: #fff !important;
  margin-right: 5px;
}

.news-text {
  color: #fff !important;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 768px) {
    /* tablets and desktop */
    /* .home-text-inner{
        width: 85%;
        text-align: center;
        font-size: 30px;
        font-weight: normal;
        line-height: 36px;
    } */
    .home-text-inner {
        width: 85%;
        height: fit-content;
        text-align: center;
        font-size: 1.2em;
        font-weight: normal;
        line-height: 37px;
    }
   
    
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (max-device-width : 1024px) {
    /* Styles */

   
}

@media only screen and (max-width: 1024px) {
    /* phones */
    .home-overlay{
        margin-top: 50px;
    }

    .video-contain {
         height: inherit !important;
         max-width: 100%;
    }
}


@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}

@media only screen and (max-width: 1025px) {
    /* laptops */
}