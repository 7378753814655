.nav-section{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width:85%;
}

.logo-section{

}

.logo{
    width: 120px;
    height: 44px;
    margin-left: 40px;
}

.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 95%;
    top:10px;
    z-index:20;
}

.nav-item-name {
    color: #fff;
    cursor: pointer;
    font-weight:500;
    font-size: 18px;
    

}
.nav-item-name-selected {
    color:#50A895 !important;
    border-bottom: 1px solid #50A895;
}
.white-text{
    color: #fff;
}
.black-text{
    color: #000;
}

.mobile-item-nav{
    margin-left: 20px;
    margin-bottom: 10px;
}
.mobile-item-nav-selected{
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    background-color:#50A895;
    margin-left: 0px;
}

.hamburger-menu{
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    margin:auto;
}

.drawer{
    z-index: 100000;
    padding-top: 20px;
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container{
    z-index:10000 !important;
}

@media only screen and (max-width: 768px) {    
    .logo{
        margin-left: 0px;
        margin-top: 5px
    }
}