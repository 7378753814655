.green-border {
    border-color: #2D9687 !important;
    color: #2D9687 !important;
    width: 70%;
}

.tab-content {
    color: "gray";
}

.speaker-drawer {
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.speaker-input {
    margin-bottom: 1rem;
}

.speaker-drawer-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
}