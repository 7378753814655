.join-section{
    background-image: url(../../assets/images/Join-the-intiative-image-bg.png);
    background-size: cover;
    margin-top: 65px;
    background-repeat: no-repeat;
    width:100%;
    height: 300px;
}
.input-container{
    width: 70%;
    margin: auto;
    padding: 60px;
}

.input-heading{
    color: #50A895;
    font-weight: 600;
    font-size:1.6rem;
}

.tabs{
    display: flex;
    flex-direction: row;
}

.inputs-half{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 49%;
}
.inputs-full{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label{
    color: #060606;
    font-weight: bold;
    margin-bottom:10px;
}

.input{
    background-color: #F6F6F6;
    height: 56px;
    border-radius:5px;
    padding-left: 5px;
}

.input-row{
    display: flex;
    flex-direction:row;
    width:100%;
    justify-content: space-between;
    margin-top: 10px;
   
}

.inputs-contain{
    display: flex;
    flex-direction:column;
}

.radio{
    margin-right:5px;

}

.radio-container{
    margin-bottom: 5px;
}

.margin-top{
    margin-top: 25px
}

.join-textarea{
    background-color: #F6F6F6;
    width: 100%;
    padding: 5px;
}

.join-header{
    font-size: 2.8rem;
    text-align: center;
    position: relative;
    margin-right: 20%;
    color: #fff;
    top: 50%;
    z-index: 200;
    left: 10%;
    font-weight: bold;
}





@media only screen and (max-width: 768px){

    .input-container {
        width: 100%;
        margin: auto;
        padding: 20px;
    }

    .header-init{
        font-size: 2.3rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 30%;
        z-index: 200;
        left: 10%;
        font-weight: bold;
    }

    .join-header {
        font-size: 2.2rem;
        text-align: center;
        position: absolute;
        color: #fff;
        top: 24%;
        z-index: 200;
        left: 15%;
        font-weight: bold;
    }

   
}

