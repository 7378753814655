.addButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  z-index: 1;
}

.btnftn {
  z-index: 2;
}

.case-study-btn {
  background-color: #2D9687;
  color: white;
  z-index: 2;
}

.no-events-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 32rem;
  height: 50vh;
  text-align: center;
}

.no-events-message p {
  font-size: 1.5rem;
  margin-top: 10px;
}

.no-events-message .icon {
  font-size: 3rem;
  color: #6aa783c4;
}