.footer {
    /* height: 80px; */
    background-color: #fff;
    align-items:center;
    display: flex;
    flex-direction:column;
    padding: 15px;
}

.margin-10{
    margin: 15px;
}

.footer-text{
    font-size:1.2rem
}

.Fix-image{
    width: 15%;
    height: 20%;
}

.underLine{
    
    text-decoration:underline;
    display:inline-block;
}