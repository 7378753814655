
.innerContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50%;
    height: 50%;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    overflow: hidden;
}

.mainContainer{
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100vh;
}

.buttonSubmit{
    width: 60%;
    height: 10%;
    background-color: #50A895 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}
.buttonSubmit:hover {
    color: black !important;
    border-color:#50A895 !important;
}

.img{
    width: 20%;
    height: auto; 
    margin-bottom: 1rem;
}
.form{
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .innerContainer {
        width: 80%; 
    }
    .buttonSubmit {
        width: 80%;
        color: white
    }
}

@media only screen and (max-width: 480px) {
    .innerContainer {
        width: 90%; 
    }
    .buttonSubmit {
        width: 90%;
    }
}
