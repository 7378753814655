.single-case-container{
    padding:30px;

}

.ubi-enegry-image{
    margin-left: 50px;
    margin-left: 50px;
    position: absolute;
    height: 150px;
    width: 150px;
}


.section-Study{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items:center;
}


.studyimg-container{
    display: flex;
    background-color: #E3E3E3;
    align-items:center;
    height:100vh;
}

.study-img-card{
   
    padding-left: 100px;
    padding-right: 200px;
    width: 80%;
    align-self: revert;
    margin-top: 200px;
    align-items: unset;
}

.download-casestudy{
    background-color: #3A817A;
    width: 190px;
    height: 60px;
    color: #fff;
    font-size: 18px;
    padding: 5px;
    margin-top: 20px;

}

.study-card{

    background-color: #fff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-right: 90px;
    padding-left: 90px;
    margin-left: 20px;
    text-align: center;
}

.study-container{
    width: 40%;
}


.casestudy-text{
    text-align: justify;
    padding-left: 200px;
    padding-right: 200px;
    margin: 10px;
    /* text-align: center; */
    color: #656565;
    font-size: 1rem;
    justify-items: unset;
}

.ubi-text{
    margin-left: 200px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
}

.back-to-casestudy{

    display: flex;
    justify-content: space-around;
    padding: 75px;
    padding-bottom: 20px;
}

.btn-back-to-casestudy{
    background-color: #3A817A;
    width: 190px;
    height: 60px;
    color: #fff;
    font-size: 18px;
    padding: 5px;
    margin-top: 20px;
}

.section-stats{
    display: flex;
    /* margin-left: 100px; */
    flex-direction: row;
    width: 100%;
    background: #3A817A;
}

.hover-green:hover{
    background-color: #000;
    transition: 0.7s
}

.stat{

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    padding-bottom: 10;
    margin-left: 200px;
    display: flex;
    flex-direction: column;
}

.stat-header{
    font-family: "Gilroy", Sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #fff;
}

.stat-text{
    font-family: "Gilroy", Sans-serif;
    font-weight: normal;
    line-height: 20px;
    color: #fff;
}
.doc{
    text-align: center;

}

.doc-text{

    margin-top: 50px;
    color: #3A817A;
    font-size: 1.8rem;
    font-weight: bold;

}

.card{

    display: flex;
    flex-direction: row;
    width: 87%;
    padding-left: 200px;
    padding-bottom: 20px;
  
}

.case-study-header{
    /* font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px; */

    font-size: 1.4rem;
    width: 70%;
    font-weight: bold;
    margin-bottom: 10px;
    width: 98%;
}

.case-page-text {

    width: 100%;
    color: #656565;
    font-size: 1rem;
    text-align: center;
    overflow-clip-margin: inherit;
    overflow: inherit;
}

.book-pic{
    max-width: 80%;
    height:auto
}

.back-btns{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-bottom: 20px;
}
.arrow{
    margin-top: 18px;
    width: 18%;
}

.back-text{
    
    font-size: 0.9rem;
    margin-top: 15px;

}






@media only screen and (max-width: 768px){

    /* study-img-card {
        padding-left: 100px;
        padding-right: 200px;
        align-self: revert;
        margin-top: 200px;
        align-items: unset;
    } */
   


    /* .casestudy-text{
        text-align: justify;
        margin: 10px;
        color: #656565;
        font-size: 1rem;
        justify-items: unset;
    }

    .ubi-enegry-image {
        display: flex;
        position: relative;
        flex-direction: center;
        margin-left: 250px;
        height: 150px;
        width: 150px;

    } */
    
    /* .ubi-text {
        margin-left: 200px;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: left;
    } */

    .section-stats {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #3A817A;
    }

    .stat {
        text-align: center;
        padding-right: 148px;
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        margin-right: 500px;
        width: 100%;
    }
  
    .study-container {
        width: 400px;
    }

    .ubi-enegry-image {
        margin-left: 70px;
        margin-bottom: 50px;
        position: absolute;
        height: 180px;
        width: 200px;
    }

    .ubi-text {
        margin-left: 2px;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: left;
        margin-right: 50px;
        margin-top: 170px;
        width: max-content;
    }
    .casestudy-text {
        display: flex;
        /* text-align: justify; */
        /* padding-left: 200px; */
        /* padding-right: 200px; */
        margin: 10px;
        /* text-align: center; */
        color: #656565;
        font-size: 1rem;
        justify-items: unset;
        width: 330px;
        margin-right: 300px;
        margin: auto;
        align-content: center;
        align-items: center;
        padding-left: initial;
        padding-right: initial;
    }

    .card {
        display: flex;
        width: auto;
        flex-direction: column;
        /* flex-direction: column; */
        /* margin-left: 300px; */
        /* margin-right: 500px; */
    }

    .study-img-card {
        margin-right: 230px;
        padding-left: 100px;
        padding-right: 200px;
        width: 80%;
        margin-top: 40px;
    }
    
    .vid-frame{
        display: flex;
    }

    .card {
        display: flex;
        flex-direction: column;
        margin-left: 300px;
        margin-right: 500px;
    }

    .yt-vid {
        padding: 30px;
    }

    
    .P1-text{
        
    }

    iframe, object, embed {
        max-width: 100%;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        padding-top: 25px;
        height: 0;
    }
      
    .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

    .doc-text{
        margin-top: 50px;
        color: #3A817A;
        font-size: 1.2rem;
        font-weight: bold;
    }

  

   

    
}