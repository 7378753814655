.btnftn{
    background-color: #2D9687;
    padding: 8px;
    display: flex;
    color: white;
    align-items: center;
    border-radius: 5px;
}

.tab-button{
    min-width: 10%;
    justify-content: center
}
.panelcard{
    min-width: 200px !important;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}